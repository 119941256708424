.Form {
  /* This is what you need to edit each time. */

  /* Bold color that contrasts with the website's background. */
  --background: #cf7500;

  /* Related color to the background, used for hilighting form entries etc. */
  --background-alt: #f0a500;

  /* Not the text color on the website, assume it's going to be on this bolder
   * color.*/
  --text-color: #1a1c20;

  /* Unfortunately necessary to inject the font from the website. */
  --form-font: 'Lato', sans-serif;
  /* End edit */

  font-family: var(--form-font);
  padding: 12px;
}

.Label,
.ToggleLabel {
  display: block;
  font-size: 18px;
  padding: 24px 0 8px 0;
}

.TextInput:disabled,
.TextArea:disabled,
.Select:disabled {
  color: var(--text-color);
  opacity: 0.4;
}

.Button {
  background: var(--background);
  border-radius: 2px;
  border: none;
  color: var(--text-color);
  font-family: var(--form-font);
  font-size: 18px;
  outline: none;
  padding: 18px 24px;
  cursor: pointer;
}

.TextInput,
.TextArea,
.Select,
.Option {
  background: var(--background);
  border-radius: 2px;
  border: none;
  caret-color: var(--text-color);
  color: var(--text-color);
  font-family: var(--form-font);
  font-size: 18px;
  outline: none;
  padding: 18px 24px;
  width: 100%;
}

.TextInput::placeholder,
.TextArea::placeholder {
  color: var(--text-color);
  opacity: 0.4;
}

.Button:hover,
.Button:focus,
.TextInput:hover,
.TextArea:hover,
.TextInput:focus,
.TextArea:focus,
.Select:hover,
.Select:focus,
.Option:hover,
.Option:focus {
  border: 2px solid var(--background-alt);
}

.TextInput::selection,
.TextArea::selection {
  background: var(--background-alt);
}

.TextArea {
  resize: none;
}

.SelectWrapper {
  position: relative;
}

.SelectArrow {
  color: var(--text-color);
  position: absolute;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.Select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.Select::-ms-expand {
  display: none;
}

.ToggleLabel {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 0px;
  outline: none;
  max-width: 100%;
}

.Toggle {
  opacity: 0;
  display: none;
}

.ToggleOuterCircle {
  border-radius: 50%;
  border: 2px solid var(--background);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.ToggleLabel:focus > .ToggleOuterCircle {
  border: 2px solid var(--background-alt);
}

.ToggleLabel:focus .ToggleInnerCircle {
  background: var(--background-alt);
}

.Toggle + .ToggleInnerCircle {
  background: var(--background);
  border-radius: 50%;
  bottom: -100px;
  height: 20px;
  left: 8px;
  position: absolute;
  width: 20px;
  transition: all 0.2s ease;
}

.Toggle:checked + .ToggleInnerCircle {
  bottom: 8px;
}
