@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro&display=swap);
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato';
  padding: 12px;
  background: #1a1c20;
  margin: 0;
  color: #f4f4f4;
}

.Browser .form-container {
  max-width: 650px;
  margin: 0 auto;
}

.Browser table {
  text-align: left;
  margin: 0 auto;
}

.Browser th,
.Browser td {
  padding: 2px 8px;
}

.Browser .selection {
  max-width: 800px;
  margin: 0 auto;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(244, 244, 244, 0.2);
  border-right: 1.1em solid rgba(244, 244, 244, 0.2);
  border-bottom: 1.1em solid rgba(244, 244, 244, 0.2);
  border-left: 1.1em solid #f4f4f4;
  transform: translateZ(0);
  -webkit-animation: load8 0.5s infinite linear;
  animation: load8 0.5s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.FormStyles_Form__3jhXp {
  /* This is what you need to edit each time. */

  /* Bold color that contrasts with the website's background. */
  --background: #cf7500;

  /* Related color to the background, used for hilighting form entries etc. */
  --background-alt: #f0a500;

  /* Not the text color on the website, assume it's going to be on this bolder
   * color.*/
  --text-color: #1a1c20;

  /* Unfortunately necessary to inject the font from the website. */
  --form-font: 'Lato', sans-serif;
  /* End edit */

  font-family: var(--form-font);
  padding: 12px;
}

.FormStyles_Label__3nDVO,
.FormStyles_ToggleLabel__2X4-o {
  display: block;
  font-size: 18px;
  padding: 24px 0 8px 0;
}

.FormStyles_TextInput__1KiC5:disabled,
.FormStyles_TextArea__2onII:disabled,
.FormStyles_Select__o_HSH:disabled {
  color: var(--text-color);
  opacity: 0.4;
}

.FormStyles_Button__QAbPn {
  background: var(--background);
  border-radius: 2px;
  border: none;
  color: var(--text-color);
  font-family: var(--form-font);
  font-size: 18px;
  outline: none;
  padding: 18px 24px;
  cursor: pointer;
}

.FormStyles_TextInput__1KiC5,
.FormStyles_TextArea__2onII,
.FormStyles_Select__o_HSH,
.FormStyles_Option__2Qbsh {
  background: var(--background);
  border-radius: 2px;
  border: none;
  caret-color: var(--text-color);
  color: var(--text-color);
  font-family: var(--form-font);
  font-size: 18px;
  outline: none;
  padding: 18px 24px;
  width: 100%;
}

.FormStyles_TextInput__1KiC5::-webkit-input-placeholder, .FormStyles_TextArea__2onII::-webkit-input-placeholder {
  color: var(--text-color);
  opacity: 0.4;
}

.FormStyles_TextInput__1KiC5:-ms-input-placeholder, .FormStyles_TextArea__2onII:-ms-input-placeholder {
  color: var(--text-color);
  opacity: 0.4;
}

.FormStyles_TextInput__1KiC5::-ms-input-placeholder, .FormStyles_TextArea__2onII::-ms-input-placeholder {
  color: var(--text-color);
  opacity: 0.4;
}

.FormStyles_TextInput__1KiC5::placeholder,
.FormStyles_TextArea__2onII::placeholder {
  color: var(--text-color);
  opacity: 0.4;
}

.FormStyles_Button__QAbPn:hover,
.FormStyles_Button__QAbPn:focus,
.FormStyles_TextInput__1KiC5:hover,
.FormStyles_TextArea__2onII:hover,
.FormStyles_TextInput__1KiC5:focus,
.FormStyles_TextArea__2onII:focus,
.FormStyles_Select__o_HSH:hover,
.FormStyles_Select__o_HSH:focus,
.FormStyles_Option__2Qbsh:hover,
.FormStyles_Option__2Qbsh:focus {
  border: 2px solid var(--background-alt);
}

.FormStyles_TextInput__1KiC5::selection,
.FormStyles_TextArea__2onII::selection {
  background: var(--background-alt);
}

.FormStyles_TextArea__2onII {
  resize: none;
}

.FormStyles_SelectWrapper__uFF9H {
  position: relative;
}

.FormStyles_SelectArrow__1Ajoe {
  color: var(--text-color);
  position: absolute;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.FormStyles_Select__o_HSH {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.FormStyles_Select__o_HSH::-ms-expand {
  display: none;
}

.FormStyles_ToggleLabel__2X4-o {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 0px;
  outline: none;
  max-width: 100%;
}

.FormStyles_Toggle__xJIHO {
  opacity: 0;
  display: none;
}

.FormStyles_ToggleOuterCircle__2ADVx {
  border-radius: 50%;
  border: 2px solid var(--background);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.FormStyles_ToggleLabel__2X4-o:focus > .FormStyles_ToggleOuterCircle__2ADVx {
  border: 2px solid var(--background-alt);
}

.FormStyles_ToggleLabel__2X4-o:focus .FormStyles_ToggleInnerCircle__2KBwQ {
  background: var(--background-alt);
}

.FormStyles_Toggle__xJIHO + .FormStyles_ToggleInnerCircle__2KBwQ {
  background: var(--background);
  border-radius: 50%;
  bottom: -100px;
  height: 20px;
  left: 8px;
  position: absolute;
  width: 20px;
  transition: all 0.2s ease;
}

.FormStyles_Toggle__xJIHO:checked + .FormStyles_ToggleInnerCircle__2KBwQ {
  bottom: 8px;
}

