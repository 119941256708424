@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato';
  padding: 12px;
  background: #1a1c20;
  margin: 0;
  color: #f4f4f4;
}

.Browser .form-container {
  max-width: 650px;
  margin: 0 auto;
}

.Browser table {
  text-align: left;
  margin: 0 auto;
}

.Browser th,
.Browser td {
  padding: 2px 8px;
}

.Browser .selection {
  max-width: 800px;
  margin: 0 auto;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(244, 244, 244, 0.2);
  border-right: 1.1em solid rgba(244, 244, 244, 0.2);
  border-bottom: 1.1em solid rgba(244, 244, 244, 0.2);
  border-left: 1.1em solid #f4f4f4;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.5s infinite linear;
  animation: load8 0.5s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
